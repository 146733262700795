var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("SelectStore", { on: { selectStore: _vm.handleSelectStore } }),
      _c("div", { staticClass: "right-container" }, [
        _c(
          "div",
          { staticClass: "list-container", style: { height: _vm.height } },
          [
            _c("div", { staticClass: "header" }, [_vm._v("骑手取货管理")]),
            _c(
              "div",
              { staticClass: "toolbar" },
              [
                _c(
                  "el-input",
                  {
                    style: {
                      width: "250px",
                      marginTop: "15px",
                      float: "right",
                    },
                    attrs: { placeholder: "查询关键字", clearable: true },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.search },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "table",
                attrs: {
                  data: _vm.ordFreightList,
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    align: "center",
                    width: "60px",
                    index: _vm.getIndex,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "取货号",
                    align: "center",
                    width: "90px",
                    prop: "cFetch",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "门店名称",
                    align: "center",
                    width: "160px",
                    prop: "sStore",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "配送单号", align: "center", width: "140px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return _vm.view(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.cSys))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "距离", align: "center", width: "80px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "link" }, [
                            _vm._v(_vm._s(scope.row.nDistance)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "货品", align: "center", width: "80px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return _vm.viewOrdPicking(scope.row)
                                },
                              },
                            },
                            [_vm._v("明细")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "骑手", align: "center", width: "80px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "link" }, [
                            _vm._v(_vm._s(scope.row.sRider)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "手机", align: "center", width: "120px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "link" }, [
                            _vm._v(_vm._s(scope.row.cRiderPhone)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "配送接单时间",
                    align: "center",
                    width: "130px",
                    prop: "tReceive",
                    formatter: _vm.dateFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "状态", align: "center", width: "60px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "link" }, [
                            _vm._v(
                              _vm._s(_vm.getFreightStatus(scope.row.bStatus))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "center",
                    label: "操作",
                    width: "70px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              style: { marginTop: "15px" },
              attrs: {
                background: "",
                layout: _vm.pageLayout,
                total: _vm.total,
                "current-page": _vm.pageNum,
                "page-sizes": _vm.pageSelect,
                "page-size": _vm.pageSize,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
            _c("ViewOrdPickingDetail", {
              attrs: {
                visible: _vm.showViewOrdPickingDetail,
                rPicking: _vm.currentRPicking,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showViewOrdPickingDetail = $event
                },
                close: _vm.handleCloseViewOrdPickingDetail,
              },
            }),
            _c("ViewOrdFreightDetail", {
              attrs: {
                visible: _vm.showViewOrdFreightDetail,
                sid: _vm.currentSid,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showViewOrdFreightDetail = $event
                },
                close: _vm.handleCloseViewOrdFreightDetail,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }